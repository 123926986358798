@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

html,
body {
  @apply font-montserrat;
}

body {
  color: #3b3c3d;
}

.border-1 {
  border-width: 1px;
}
*[onClick],
*[href] {
  cursor: pointer;
}

html .rc-slider-handle:active {
  box-shadow: 0 0 16px 0 rgba(200, 170, 87, 0.64);
}

/* Write your own custom component styles here */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

body ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
body ::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
body ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
body ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
body ::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.6);
}

/* Your own custom utilities */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.admin-table-h {
  max-height: calc(100vh - 168px);
  min-height: calc(100vh - 168px);
  height: 100%;
}

.admin-table-h ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
